import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import { getDecodeduser } from '../utils';
// import routes from "../route";

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading() {
        return <div>Loading...</div>
    }
});

const CLientLayout = Loadable({
    loader: () => import('./layout/ClientLayout'),
    loading() {
        return <div>Loading...</div>
    }
})
const Layout404 = Loadable({
    loader: () => import('./layout/500'),
    loading() {
        return <div>Loading...</div>
    }
})
class App extends Component {
    componentDidMount() {
        const user = getDecodeduser()
        const history= this.props.history;
        if (user && user.role==="admin") {
            history.push('/admin')
        } else  if (user && user.role==="user") {
            history.push('/dashboard')
        }
    }
    render() {
        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<h1>Loading</h1>}>
                        <Switch>
                            {/* {menu} */}
                            <Route path="/admin" component={AdminLayout} />
                            <Route path="/500" component={Layout404} />
                            <Route path="/" component={CLientLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}


export default withRouter(App);
