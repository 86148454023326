import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import userActionReducer from './store/reducers/userActionReducer';

import config from './config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
const creducer = combineReducers({
    reducer,
    user: userActionReducer
})
// const store = (window.devToolsExtension
//     ? window.devToolsExtension()(createStore)
//     : createStore)(creducer);

const store = createStore(creducer);

function createNotification(type, value) {
    switch (type) {
        case 'info':
            NotificationManager.info(value, '', 5000);
            break;
        case 'success':
            NotificationManager.success(value, '', 5000);
            break;
        case 'warning':
            NotificationManager.warning(value, '', 5000);
            break;
        case 'error':
            NotificationManager.error(value, '', 5000);
            break;
        default: break;
    }
};

const app = (
    <Provider store={store}>
        <NotificationContainer />
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
export { createNotification };