import { getDecodeduser } from "../../utils";
const initialState = getDecodeduser();
export default function (state = initialState, action) {
  switch (action.type) {
    case 'SIGN_IN':
      return { ...state, ...action.payload };
    case 'SIGN_OUT':
      return null;
    default:
      return state;
  }
}