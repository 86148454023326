import { Cookies } from 'react-cookie';
import jwt from 'jsonwebtoken';

export const setSession = token => {
    let cookies = new Cookies();
    if (token) cookies.set('ccr-t', token, { path: '/' });
    else cookies.remove('ccr-t', { path: '/' });
};
export const getUserToken = () => {
    const user = getLoggedInUser();
    return user;
}
export const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('ccr-t');
    return user ? (typeof jwt.decode(user) == 'object' ? user : JSON.parse(user)) : null;
};
export const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    } else {
        return true;
    }
};
export const isUserAuthenticatedClient = () => {
    const user = getDecodeduser();
    if (!user) {
        return false;
    } else {
        if (user.role === 'user') {
            return true;
        } else {
            return false;
        }
    }
};
export const isUserAuthenticatedAdmin = () => {
    const user = getDecodeduser();
    if (!user) {
        return false;
    } else {
        if (user.role === 'admin') {
            return true;
        } else {
            return false;
        }
    }
};
export const getDecodeduser = () => {
    const user = getLoggedInUser();
    if (!user) {
        return null
    } else {
        return jwt.decode(user);
    }
}
export const loadHeaders = () => {
    const config = {
        headers: {
            "Content-type": "Application/json",
            "x-auth-token": getUserToken()
        }
    }
    return config
}

export const loadHeadersformdata = () => {
    const config = {
        headers: {
            "Content-type": "Application/form-data",
            "x-auth-token": getUserToken()
        }
    }
    return config
}